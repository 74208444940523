html
	background-color: #ededed !important
	height: 100vh
	width: 100%


body
	background-color: #e7e7e7 !important
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif
	height: 100%
	width: 100%


#root
	height: 100%
